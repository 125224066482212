<template>
  <div>
    <treatment-history-detail
      backRoute="SuperAdminNurseDetails"
      patientDetailRoute="SuperAdminPatientDetail"
    />
  </div>
</template>
<script>
import PatientTreatmentHistoryDetails from "../../general-component/treatment-history/TreatmentDetails";
export default {
  name: "SuperAdminPatientDetails",
  components: {
    "treatment-history-detail": PatientTreatmentHistoryDetails
  },
  data() {
    return {};
  }
};
</script>
